const adminGuide =
    "https://access.redhat.com/documentation/en-us/red_hat_build_of_keycloak/26.0/html-single/server_administration_guide/index";

const keycloakHomepageURL = "https://access.redhat.com/documentation/en-us/red_hat_build_of_keycloak";


export default {
  documentationUrl: adminGuide,
  clientsUrl: `${adminGuide}#assembly-managing-clients_server_administration_guide`,
  clientScopesUrl: `${adminGuide}#client_scopes`,
  realmRolesUrl: `${adminGuide}#assigning_permissions_using_roles_and_groups`,
  usersUrl: `${adminGuide}#assembly_managing-users_server_administration_guide`,
  groupsUrl: `${adminGuide}#proc-managing-groups_server_administration_guide`,
  sessionsUrl: `${adminGuide}#managing_user_sessions`,
  eventsUrl: `${adminGuide}#configuring_auditing_to_track_events`,
  realmSettingsUrl: `${adminGuide}#configuring_realms`,
  authenticationUrl: `${adminGuide}#configuring-authentication_server_administration_guide`,
  identityProvidersUrl: `${adminGuide}#identity_broker`,
  userFederationUrl: `${adminGuide}#user-storage-federation`,
  documentation: `${keycloakHomepageURL}/26.0`,
  guides: `${keycloakHomepageURL}/26.0`,
  community: `${keycloakHomepageURL}/26.0`,
  blog: `${keycloakHomepageURL}/26.0`,
};
